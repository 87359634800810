:root {
    
    --widthSmall: 27vw;
    --heightSmall: 42vh;
    --borderMain: (0pc solid black);
    --margin-left: 2vw;
}

.sidemenu{
    width:20vw; 
    height:100vh;
   
}
.bigcard{
    width:44vw; 
    height: 42vh;
    border: 1px solid black; 
    display: flex;
    flex-direction: column;

}
.smallcard{
    width:27vw; 
    height: 42vh;
    border: 1px solid black; 
    display: flex;
    flex-direction: column;
    margin-left: 2vw;
    
}
.smallcardleft{
    width:25vw; 
    height: 42vh;
    border: 1px solid black; 
    display: flex;
    flex-direction: column;
    margin-right: 2vw;
}
.smallcardbottom{
    width:23vw; 
    height: 42vh;
    border: 1px solid black; 
    display: flex;
    flex-direction: column;
    margin-right: 2vw;
}
.smallcardbottomRight{
    width:17vw; 
    height: 42vh;
    border: 1px solid black; 
    display: flex;
    flex-direction: column;
    margin-right: 2vw;
}

.calcard{
    width:70vw; 
    height: 60vh;
    border: 1px solid black; 
    display: flex;
    margin:0 auto;
    flex-direction: column;
    margin-top:10vh;

}
