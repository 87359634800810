label {
    display: block;
    margin-top: 10px;
}

.back-screen1 {
    position: absolute;
    opacity: 0.75;
    width: 100%;
    height: 100%;
    z-index: 1;
    
}
@media only screen and (max-width: 450px){
    .back-screen1 {
        position: absolute;
        opacity: 0.75;
        width: 100%;
        height: 100%;
        z-index: 1;
        object-fit: cover;
        
    }

}

.scroller {
    
    height: 100%;
    overflow-y: scroll; /* Add the ability to scroll */
}

    /* Hide scrollbar for Chrome, Safari and Opera */
    .scroller::-webkit-scrollbar {
        display: none;
        width: 0 !important;
    }

/* Hide scrollbar for IE, Edge and Firefox */
.scroller {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: 3px; /* Firefox */
    overflow: -moz-scrollbars-none;
}
.scroller::-webkit-scrollbar {
    width: 3px;
      
    
}

/* Scrollbar Track */
.scrollerLogin::-webkit-scrollbar {
    width: 12px;
}

/* Scrollbar Handle */
.scrollerLogin::-webkit-scrollbar-thumb {
    background-color: #6c757d; /* Set the color of the scrollbar handle */
    border-radius: 6px; /* Set the border radius of the scrollbar handle */
}

/* Scrollbar Handle on hover */
.scrollerLogin::-webkit-scrollbar-thumb:hover {
    background-color: #495057; /* Set the color of the scrollbar handle on hover */
}

/* Scrollbar Track on hover */
.scrollerLogin::-webkit-scrollbar-track:hover {
    background-color: #f1f1f1; /* Set the color of the scrollbar track on hover */
}

/* Scrollbar Handle on active state */
.scrollerLogin::-webkit-scrollbar-thumb:active {
    background-color: #343a40; /* Set the color of the scrollbar handle on active state */
}

/* Scrollbar Track on active state */
.scrollerLogin::-webkit-scrollbar-track:active {
    background-color: #e9ecef; /* Set the color of the scrollbar track on active state */
}

/* Firefox scrollbar */
.scrollerLogin {
    scrollbar-width: thin;
    scrollbar-color: #6c757d #f8f9fa; /* Set the color of the scrollbar handle and track for Firefox */
}

/* Firefox scrollbar on hover */
.scrollerLogin:hover {
    scrollbar-color: #495057 #f1f1f1; /* Set the color of the scrollbar handle and track on hover for Firefox */
}

/* Firefox scrollbar on active state */
.scrollerLogin:active {
    scrollbar-color: #343a40 #e9ecef; /* Set the color of the scrollbar handle and track on active state for Firefox */
}

.myContainer{
    width:100%;
    height:100%;
}

.card-container.card {
    max-width: 20%;
    padding: 40px 40px;
}
.change-button {
    background-color: Transparent;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    border-color: gray;
    outline: none;
    border-radius: 8px;
    padding: 5px;
    margin: 8px;
}
    .change-button:hover {
        color: #f7f7f7;
        background-color:#6ec247;
    }
    .card {
        background-color: #f7f7f7;
        padding: 20px 25px 30px;
        margin: 0 auto 25px;
        margin-top: 50px;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        border-radius: 2px;
        -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    }

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.profile-img-carda {
    width: 100%;
    height: 100%;
    
    
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;

}

.profile-img-cardabc {
    width: 150px;
    height: 150px;
    
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
/* Popup style */
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}
.popup-boxa1 {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}
.popup-boxa1splash {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}
.popup-boxa {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.box {
    position: relative;
    width: 25%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
}




.boxa {
    position: relative;
    width: 25%;
    box-shadow: 1px 1px gray;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 10px;
    padding: 20px;
}

.close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
}



.huv{
    cursor:pointer
}


@media only screen and (max-width: 1400px){
    .card-container.card {
        max-width: 30%;
        padding: 40px 40px;
    }
}

@media only screen and (max-width: 800px){
    .card-container.card {
        max-width: 96%;
        padding: 40px 40px;
    }
}
